// Generated by Framer (c815cea)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {az58PxKgO: {hover: true}};

const cycleOrder = ["az58PxKgO"];

const serializationHash = "framer-JmCjJ"

const variantClassNames = {az58PxKgO: "framer-v-xwnx4t"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "az58PxKgO", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-xwnx4t", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"az58PxKgO"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"az58PxKgO-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-rubxbg"} data-framer-name={"linked in"} fill={"rgba(0,0,0,1)"} intrinsicHeight={53} intrinsicWidth={53} layoutDependency={layoutDependency} layoutId={"XPuevNnJq"} svg={"<svg width=\"53\" height=\"53\" viewBox=\"0 0 53 53\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<rect x=\"0.5\" y=\"0.5\" width=\"51.1053\" height=\"51.1053\" rx=\"25.5526\" stroke=\"white\"/>\n<path d=\"M20.3155 18.2117C20.3152 18.77 20.0932 19.3053 19.6982 19.6999C19.3032 20.0945 18.7676 20.316 18.2093 20.3158C17.651 20.3155 17.1157 20.0934 16.7211 19.6984C16.3265 19.3034 16.1049 18.7679 16.1052 18.2096C16.1055 17.6512 16.3276 17.1159 16.7226 16.7213C17.1175 16.3267 17.6531 16.1052 18.2114 16.1055C18.7697 16.1057 19.3051 16.3278 19.6997 16.7228C20.0943 17.1178 20.3158 17.6533 20.3155 18.2117ZM20.3787 21.8746H16.1684V35.0528H20.3787V21.8746ZM27.0309 21.8746H22.8417V35.0528H26.9888V28.1374C26.9888 24.285 32.0096 23.9271 32.0096 28.1374V35.0528H36.1673V26.7059C36.1673 20.2116 28.7361 20.4536 26.9888 23.6429L27.0309 21.8746Z\" fill=\"white\"/>\n</svg>\n"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JmCjJ.framer-s5o6je, .framer-JmCjJ .framer-s5o6je { display: block; }", ".framer-JmCjJ.framer-xwnx4t { cursor: pointer; height: 53px; overflow: hidden; position: relative; width: 53px; }", ".framer-JmCjJ .framer-rubxbg { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 53
 * @framerIntrinsicWidth 53
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"n9_Zjnwf6":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerXkbdaUGTc: React.ComponentType<Props> = withCSS(Component, css, "framer-JmCjJ") as typeof Component;
export default FramerXkbdaUGTc;

FramerXkbdaUGTc.displayName = "linked in";

FramerXkbdaUGTc.defaultProps = {height: 53, width: 53};

addFonts(FramerXkbdaUGTc, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})